<template>
  <div class="container">
    <PublicHeader
      :totalName="$t('recharge').title"
      :rightTitle="$t('recharge').ls"
      routerName="Home"
      rightRouter="rechargeHistory"
    />
    <div class="content">
      <p>{{ $t('recharge').td }}</p>
      <van-cell
        is-link
        :title="item.Name"
        v-for="item in list"
        :key="item.Id"
        @click="goServe"
      >
        <!-- @click="toForm(item)" -->
        <template #icon>
          <img :src="item.Icon" alt="" />
        </template>
      </van-cell>
      <van-cell
        is-link
        :title="$t('msgbank').bankCard"
        @click="goServe"
        v-if="basicConfig.IsBank"
      >
        <template #icon>
          <img src="@/assets/new/bankcard.png" alt="" />
        </template>
      </van-cell>
    </div>
    <div class="link" v-for="(item, index) in regTypeArr" :key="index">
      <img :src="item.Image" alt="" />
      <div class="name">{{ item.Name }}</div>
      <div class="btn" @click="onCopy(item.OutsideUrl)">
        {{ $t('recharge').copy }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import PublicHeader from '@/components/publicHeaderNew'
import transactionApi from '@/api/transaction'
import userApi from '@/api/user'
export default {
  components: {
    PublicHeader
  },
  computed: {
    ...mapGetters(['basicConfig', 'lang', 'connection'])
  },
  data() {
    return {
      list: [],
      regTypeArr: []
    }
  },
  async mounted() {
    const res = await transactionApi.RechargeChannel()
    this.list = res.RechargeChannels
    this.regTypeArr = res.RechargeOutsideUrls
  },
  methods: {
    go(link) {
      window.open(link)
    },
    async goServe() {
      let str = this.basicConfig.ServiceUrl
      const res = await this.getUserInfo()
      let userInfo = {
        name: res.UserName,
        uid: res.Code
      }
      if (str.indexOf('?') === -1) {
        str += '?userInfo=' + JSON.stringify(userInfo)
      } else {
        str += '&userInfo=' + JSON.stringify(userInfo)
      }
      str += `&lang=${this.lang === 'zh' ? 'zh-CN' : 'en-US'}`
      location.href = str
    },
    async getUserInfo() {
      const userInfo = await userApi.userInfo()
      return userInfo
    },
    //复制方法
    onCopy(text) {
      return new Promise((resolve) => {
        if (navigator.clipboard?.writeText) {
          this.$toast(this.$t('recharge').copySuc)
          return resolve(navigator.clipboard.writeText(text))
        }
        // 创建输入框
        const textarea = document.createElement('textarea')
        document.body.appendChild(textarea)
        // 隐藏此输入框
        textarea.style.position = 'absolute'
        textarea.style.clip = 'rect(0 0 0 0)'
        // 赋值
        textarea.value = text
        // 选中
        textarea.select()
        // 复制
        document.execCommand('copy', true)
        textarea.remove()
        this.$toast(this.$t('recharge').copySuc)
        return resolve(true)
      })
    },
    toForm(info) {
      this.$router.push({
        name: 'RechargeForm',
        query: {
          info
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.container {
  padding-top: 44px;
  .van-cell {
    img {
      width: 25px;
      height: 26px;
      margin-right: 10px;
    }
  }
  .content {
    p {
      color: #333;
      font-size: 14px;
      padding: 10px 15px;
    }
    background: #fff;
    margin: 15px 15px 30px;
    border-radius: 5px;
    overflow: hidden;
  }
  .link {
    margin: 10px 20px;
    text-align: center;
    display: flex;
    justify-content: space-between;
    align-items: center;
    img {
      height: 30px;
    }
    .btn {
      font-size: 10px;
      padding: 7px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 5px;
      background: #8e1473;
    }
  }
}
</style>
